<template>
    <div class="calendar">
        <nav class="baydin__nav text-center pt-3 pb-3 shadow-sm">
            <h3 class="fw-bold mb-0">နေ့ကောင်းရက်သာ</h3>
        </nav>

        <div class="container calendar-bg-img" style="color:yellow;">
            <div class="text-center" style="background-color:#80808085;">
                <div class="card-body">
                    <div class="mb-4">
                        <p>
                            ပြောင်းလဲလိုသည့် ပြက္ကဒိန် အမျိုးအစားရွေးပါ
                        </p>
                        <div class="ms-3">
                            <select
                                class="form-control"
                                v-model="lang_type"
                                id="lang_type"
                            >
                                <option value="myanmar">Myanmar</option>
                                <option value="english">English</option>
                            </select>
                        </div>
                    </div>

                    <p class="text-center" v-if="lang_type == 'myanmar'">
                        မြန်မာ နေ့စွဲ ရိုက်ထည့်၍ ရှာပါ
                    </p>
                    <p class="text-center" v-else>
                        ခရစ် သက္ကရာဇ် ဖြင့် ရှာမည်
                    </p>

                    <div
                        v-if="lang_type == 'myanmar'"
                        class="myn-date mt-4 mb-4"
                    >
                        <div
                            style="width: 97%;
                                    margin-left: 3%;"
                        >
                            <div>
                                <input
                                    v-model="eng_date"
                                    type="date"
                                    class="form-control"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-else class="eng-date mt-4 mb-4">
                        <div class="form-group mb-3">
                            <label for="date">Year</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Years"
                                v-model="mm_date_year"
                            />
                        </div>
                        <div class="form-group mb-3">
                            <label for="month">Month</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Months"
                                v-model="mm_date_month"
                            />
                        </div>
                        <div class="form-group mb-3">
                            <label for="type">Type</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Type"
                                v-model="mm_date_type"
                            />
                        </div>
                        <div class="form-group mb-3">
                            <label for="moon">Moon</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Moon"
                                v-model="mm_date_moon"
                            />
                        </div>
                        <div class="form-group mb-3">
                            <label for="day">Day</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Days"
                                v-model="mm_date_day"
                            />
                        </div>
                    </div>

                    <div class="text-center mt-3 mb-3">
                        <button
                            class="btn btn-primary"
                            @click="generateCalendar"
                        >
                            Change
                        </button>
                    </div>

                    <div class="card" style="text-align:left; ">
                        <div
                            class="card-body"
                            style="color:black!important;"
                            v-if="calendar_date"
                        >
                            <div>နှစ် : {{ calendar_date.year }}</div>
                            <div>လ : {{ calendar_date.month }}</div>
                            <div>ရက် : {{ calendar_date.day }}</div>
                            <div>
                                အခြားအချက်အလက်များ : {{ calendar_date.info }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            lang_type: 'myanmar',
            eng_date: '',
            mm_date_year: '',
            mm_date_month: '',
            mm_date_type: '',
            mm_date_moon: '',
            mm_date_day: '',
            calendar_date: {},
        };
    },

    methods: {
        generateCalendar() {
            if (this.lang_type == 'myanmar') {
                const date = new Date(this.eng_date);
                let formatted_date =
                    String(date.getDate()).padStart(2, '0') +
                    '-' +
                    String(date.getMonth() + 1).padStart(2, '0') +
                    '-' +
                    date.getFullYear();
                // console.log(formatted_date, 'format');
                var formdata = new FormData();
                formdata.append('english_date', formatted_date);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow',
                };

                fetch(
                    'https://ibaydin.pyaesoneshin.com/ibaydin/public/api/calendar/mmdate',
                    requestOptions,
                )
                    .then(response => response.json())
                    .then(result => {
                        // console.log(result.data);
                        this.calendar_date = result.data.myanmar_date;
                        this.calendar_date = Object.assign(this.calendar_date, {
                            info: 'ရက်ရာဇာ၊ နဂါးခေါင်းမြောက်သို့လှည့်',
                        });
                    })
                    .catch(error => console.log('error', error));
            } else {
                var formData = new FormData();
                formData.append('year', this.mm_date_year);
                formData.append('month', this.mm_date_month);
                formData.append('month_type', this.mm_date_type);
                formData.append('moon_phase', this.mm_date_moon);
                formData.append('day', this.mm_date_day);

                var requestOptionsEn = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow',
                };

                fetch(
                    'https://ibaydin.pyaesoneshin.com/ibaydin/public/api/calendar/engdate',
                    requestOptionsEn,
                )
                    .then(response => response.json())
                    .then(result => console.log(result))
                    .catch(error => console.log('error', error));
            }
        },
    },
};
</script>
<style lang="scss">
.calendar-bg-img {
    background-image: url('~@/assets/img/calendar/padauk.jpg');
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
}
</style>
